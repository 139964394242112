
export default {
  name: "CoreButton",
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    lg: Boolean,
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    classObject() {
      return {
        'lg': this.lg,
        'primary': this.type === 'primary',
        'secondary': this.type === 'secondary',
        'red': this.type === 'red',
      }
    }
  }
}
