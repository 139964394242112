
export default {
  name: "CustomDetails",
  data () {
    return {
      detailsFlag: false,
    }
  },
  computed: {
    arrowClasses () {
      return this.detailsFlag ? ['rotate-180'] : [];
    }
  }
}
