
export default {
  name: "RialWithdraw",
  data() {
    return {
      formData: {
        amount: null,
      },
      lirFormData: {
        amount: null,
        name: '',
        iban: null
      },
      flags: {
        hasError: true,
      },
      lirPrice: 0,
      cards: {
        selected: null,
        accounts: [],
        // options: [],
        addNewCard: {
          formFlag: false,
        }
      },
      lirCards: {
        selected: null,
        accounts: [],
        // options: [],
        addNewCard: {
          formFlag: false,
        }
      },
      tabButtons: [
        // {id: 0, name: "همه"},
        { id: 0, name: "ریال" },
        { id: 1, name: "لیر" },
      ],
      selectedType: 0,
    }
  },
  computed: {
    cardsOptions() {
      let accounts = this.cards.accounts;
      let newOptions = [];
      accounts.forEach(account => {
        newOptions.push({
          id: account.id,
          icon: `${this.$config.cdnUrl}/${account.bank.image}`,
          title: account.bank.name,
          body: [
            `شماره کارت:${account.card}`,
            `شبا:${account.sheba}`,
          ]
        });
      });
      return newOptions;
    },
    lirOptions() {
      let accounts = this.lirCards.accounts;
      let newOptions = [];
      accounts.forEach(account => {
        newOptions.push({
          id: account.id,
          icon: account.turkishBank.logo,
          title: account.turkishBank.name,
          body: [
            `شماره IBAN:${account.iban}`,
          ]
        });
      });
      return newOptions;
    }
  },
  methods: {
    setBalance() {
      this.formData.amount = this.$auth.user.balance.IRT;
    },
    setBalanceLir() {
      this.lirFormData.amount = this.$auth.user.balance.IRT;
    },
    async getBankList() {
      if (this.$store.state.bankAccounts.banksList.length === 0) {
        await this.$store.dispatch("bankAccounts/bankList");
        this.bankList = this.$store.state.bankAccounts.banksList;
      } else {
        this.bankList = this.$store.state.bankAccounts.banksList;
      }
    },

    async getBankAccounts() {
      if (this.$store.state.bankAccounts.accounts.length === 0 && !this.$store.state.bankAccounts.loaded) {
        await this.$store.dispatch("bankAccounts/getAccounts");
        this.cards.accounts = this.$store.getters["bankAccounts/combine"];
      } else {
        this.cards.accounts = this.$store.getters["bankAccounts/combine"];
      }
    },


    async getBankLirAccounts() {
      try {
        let resp = await this.$axios.$get('/v1/lirAccount/all');
        this.lirPrice = resp.lirPrice.lir
        this.lirCards.accounts = resp.lirAccounts;
      } catch (error) {
        console.log(error)
      }
    },

    async withdraw() {
      if (this.cards.selected) {
        if (this.$auth.user.isKYC) {
          if (this.formData.amount && this.formData.amount >= 10000) {
            if (this.formData.amount <= this.$auth.user.balance.IRT) {
              this.$store.dispatch("dashboardPopupForm/closePopup");
              this.$store.dispatch("overlay/toggleOverlay");
              try {
                let payload = {
                  type: "IRT",
                  amount: this.formData.amount,
                  accountId: this.cards.selected.id,
                };
                // left here that server not responding as I want
                let resp = await this.$axios.$post('/v1/withdrawal', payload);
                this.$store.dispatch("messagePopup/success", `درخواست برداشت شما به مبلغ ${payload.amount} تومان با موفقیت ثبت شد.`);
                await this.$auth.fetchUser();
                this.$store.dispatch("usdtConfig/set", resp.price);
                this.$store.dispatch("lirConfig/set", resp.lirPrice);
              } catch (err) {
                this.$store.dispatch("messagePopup/error", err.response.data.message);
              } finally {
                this.$store.dispatch("overlay/toggleOverlay");
              }
            } else {
              this.$store.dispatch("messagePopup/error", "موجودی تومان شما کافی نمی باشد.");
            }
          } else {
            this.$store.dispatch("messagePopup/error", "حداقل مقدار برای برداشت، معادل 10,000 تومان می باشد.");
          }
        }
        else {
          this.$store.dispatch("messagePopup/error", "برای برداشت از حساب ابتدا می بایست احراز هویت کنید.");
        }
      } else {
        this.$store.dispatch("messagePopup/error", "لطفا یک کارت بانکی جهت برداشت انتخاب کنید.");
      }
    },

    async lirWithdraw() {
      if (this.lirCards.selected) {
        if (this.$auth.user.isKYC) {
          if (this.lirFormData.amount && this.lirFormData.amount >= 10000) {
            if (this.lirFormData.amount <= this.$auth.user.balance.IRT) {
              this.$store.dispatch("dashboardPopupForm/closePopup");
              this.$store.dispatch("overlay/toggleOverlay");
              try {
                let payload = {
                  type: "LIR",
                  amount: this.lirFormData.amount,
                  lirAccountId: this.lirCards.selected.id,
                };
                // left here that server not responding as I want
                let resp = await this.$axios.$post('/v1/withdrawal', payload);
                this.$store.dispatch("messagePopup/success", `درخواست واریز لیر شما به مبلغ ${payload.amount} تومان با موفقیت ثبت شد.`);
                await this.$auth.fetchUser();
                this.$store.dispatch("usdtConfig/set", resp.price);
                this.$store.dispatch("lirConfig/set", resp.lirPrice);
              } catch (err) {
                this.$store.dispatch("messagePopup/error", err.response.data.message);
              } finally {
                this.$store.dispatch("overlay/toggleOverlay");
              }
            } else {
              this.$store.dispatch("messagePopup/error", "موجودی تومان شما کافی نمی باشد.");
            }
          } else {
            this.$store.dispatch("messagePopup/error", "حداقل مقدار برای برداشت، معادل 10,000 تومان می باشد.");
          }
        }
        else {
          this.$store.dispatch("messagePopup/error", "برای برداشت از حساب ابتدا می بایست احراز هویت کنید.");
        }
      } else {
        this.$store.dispatch("messagePopup/error", "لطفا یک کارت بانکی جهت برداشت انتخاب کنید.");
      }
    }
  },
  async fetch() {
    await this.getBankList();
    await this.getBankAccounts();
    await this.getBankLirAccounts();
  }
}
