
export default {
  name: "AppHeader",
  data() {
    return {
      navbar: {
        flag: false,
      }
    }
  },
  methods: {
    toggleNavbar() {
      this.navbar.flag = !this.navbar.flag;
    }
  }
}
