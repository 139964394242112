
import { marked } from 'marked';
import highlight from 'highlight.js';
import 'highlight.js/styles/base16/solarized-dark.css';
export default {
    props: ['markdown'],
    computed: {
        processedMarkdown() {
            if (this.markdown === null)
                this.markdown = ''
            return marked(this.markdown, {
                highlight(md) {
                    return highlight.highlightAuto(md).value
                }
            })
        },
    },
}
