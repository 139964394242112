
import dashboardLinks from "@/data/dashboard-links.json";

export default {
  name: "AppUserNavbar",
  data () {
    return {
      dashboardMenu: {
        flag: false,
        items: dashboardLinks,
      }
    }
  },
  computed: {
    kycStatus () {
      if (this.$auth.user && !this.$auth.user.isMobileOwner) {
        return 0;
      } else if (this.$auth.user.ImageKYCStatus === 'NONE' || this.$auth.user.ImageKYCStatus === 'CANCELED') {
        return 2;
      } else if (this.$auth.user.VideoKYCStatus === 'NONE' || this.$auth.user.VideoKYCStatus === 'CANCELED') {
        return 3;
      } else {
        if (this.$auth.user.ImageKYCStatus === 'PENDING' && this.$auth.user.ImageKYCStatus === 'PENDING') {
          return 4;
        } else {
          return 4;
        }
      }
    }
  },
}
