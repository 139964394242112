import { render, staticRenderFns } from "./RialToTether.vue?vue&type=template&id=6886f39a&scoped=true"
import script from "./RialToTether.vue?vue&type=script&lang=js"
export * from "./RialToTether.vue?vue&type=script&lang=js"
import style0 from "./RialToTether.vue?vue&type=style&index=0&id=6886f39a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6886f39a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSvgIcon: require('/app/components/core/SvgIcon.vue').default,CoreInputNumberField: require('/app/components/core/input/NumberField.vue').default,CoreInputRange: require('/app/components/core/input/Range.vue').default,CoreDetailsBox: require('/app/components/core/DetailsBox.vue').default,CoreButton: require('/app/components/core/Button.vue').default,PublicTetherIcon: require('/app/components/public/tether/Icon.vue').default})
