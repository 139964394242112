
export default {
  name: "AddLirAccount",
  data() {
    return {
      newLirAccForm: {
        name: '',
        iban: 'TR'
      },
    };
  },
  computed: {

  },
  methods: {
    async addNewLirAccound() {
      const iban = this.newLirAccForm.iban.slice(2)
      if (iban.length === 24) {
        try {
          this.$store.dispatch("overlay/toggleOverlay");
          let resp = await this.$axios.$post('/v1/lirAccount', {
            iban: iban,
            name: this.newLirAccForm.name,
          });
          this.newLirAccForm.iban == "";
          this.newLirAccForm.name == "";
          this.$store.dispatch("messagePopup/success", "حساب بانکی جدید با موفقیت اضافه شد.");
          this.$store.dispatch('addLirAccount/toggleSubmit')
          this.$store.dispatch('addLirAccount/closePopup')
        } catch (err) {
          this.$store.dispatch("messagePopup/error", err.response.data.message);
        } finally {
          this.$store.dispatch("overlay/toggleOverlay");
        }
      } else {
        this.$store.dispatch("messagePopup/error", "شماره iban وارد شده نامعتبر می باشد.");
      }
    },
    close() {
      this.$store.dispatch('addLirAccount/toggleSubmit')
      this.$store.dispatch('addLirAccount/closePopup')
    }
  },
};
