
export default {
  name: "CoreLoading",
  props: {
    bgColor: {
      type: String,
      default: 'transparent'
    },
    loaderColor: {
      type: String,
      default: 'blue'
    },
    size: {
      type: String,
      default: 'base',
    }
  },
  computed: {
    bgClass() {
      return this.bgColor;
    },
    loaderClass() {
      return this.loaderColor;
    }
  }
}
