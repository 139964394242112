import { render, staticRenderFns } from "./TetherWithdraw.vue?vue&type=template&id=a8f6079e&scoped=true"
import script from "./TetherWithdraw.vue?vue&type=script&lang=js"
export * from "./TetherWithdraw.vue?vue&type=script&lang=js"
import style0 from "./TetherWithdraw.vue?vue&type=style&index=0&id=a8f6079e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8f6079e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSvgIcon: require('/app/components/core/SvgIcon.vue').default,CoreSelectCard: require('/app/components/core/select/Card.vue').default,PublicTetherIcon: require('/app/components/public/tether/Icon.vue').default,CoreInputNumberField: require('/app/components/core/input/NumberField.vue').default,CoreInputRange: require('/app/components/core/input/Range.vue').default,CoreDetailsBox: require('/app/components/core/DetailsBox.vue').default,CoreButton: require('/app/components/core/Button.vue').default})
