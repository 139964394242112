
export default {
  data() {
    return {
      isMenu: false
    }
  },

  methods: {
    goToProfile() {
      this.$router.push('/dashboard/profile')
    },

    showMenu() {
      this.isMenu = !this.isMenu
    },

    getEmailShort() {
      const first = this.$auth.user.email.slice(0, 4)
      const end = this.$auth.user.email.slice(-3)
      return first + "*****" + end
    },

    async logoutRequest() {
      await this.$auth.logout(/* .... */)
    },
  }
}
