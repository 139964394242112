
export default {
  name: "RialDeposit",
  data() {
    return {
      cards: {
        selected: null,
        accounts: [],
        // options: [],
        addNewCard: {
          formFlag: false,
        },
      },
      formData: {
        amount: null,
      },
    };
  },
  computed: {
    cardsOptions() {
      let accounts = this.cards.accounts;
      let newOptions = [];
      accounts.forEach((account) => {
        newOptions.push({
          id: account.id,
          icon: `${this.$config.cdnUrl}/${account.bank.image}`,
          title: account.bank.name,
          body: [`شماره کارت:${account.card}`, `شبا:${account.sheba}`],
        });
      });
      return newOptions;
    },
  },
  methods: {
    async getBankList() {
      if (this.$store.state.bankAccounts.banksList.length === 0) {
        await this.$store.dispatch("bankAccounts/bankList");
        this.bankList = this.$store.state.bankAccounts.banksList;
      } else {
        this.bankList = this.$store.state.bankAccounts.banksList;
      }
    },
    async getBankAccounts() {
      if (
        this.$store.state.bankAccounts.accounts.length === 0 &&
        !this.$store.state.bankAccounts.loaded
      ) {
        await this.$store.dispatch("bankAccounts/getAccounts");
        this.cards.accounts = this.$store.getters["bankAccounts/combine"];
      } else {
        this.cards.accounts = this.$store.getters["bankAccounts/combine"];
      }
    },
    async getUrl() {
      try {
        if (this.formData.amount < 10000) {
          this.$store.dispatch(
            "messagePopup/error",
            "مبلغ وارد شده کمتر از 10,000 تومان می باشد."
          );
          return;
        }
        if (!this.cards.selected) {
          this.$store.dispatch(
            "messagePopup/error",
            "یک شماره کارت کارت انتخاب نمایید."
          );
          return;
        }
        const response = await this.$axios.$post("/v1/payment", {
          amount: this.formData.amount,
          accountId: this.cards.selected.id,
        });
        window.location.href = response.url;
      } catch (error) {
        this.$store.dispatch(
          "messagePopup/error",
          error.response.data.message,
          { root: true }
        );
      }
    },
  },
  async fetch() {
    await this.getBankList();
    await this.getBankAccounts();
  },
};
