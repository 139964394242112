
export default {
  name: "TetherDeposit",

  data() {
    return {
      form: {
        flags: {
          hasError: true,
        },
        data: {
          txid: null,
          amount: 0,
        },
      },
    };
  },

  methods: {
    async deposit() {
      if (!this.form.flags.hasError && this.form.data.amount) {
        this.$store.dispatch("overlay/toggleOverlay");
        try {
          const response = await this.$axios.$post(
            "/v1/deposit",
            this.form.data
          );
          this.$store.dispatch(
            "messagePopup/success",
            `درخواست واریز تتر با موفقیت ثبت گردید.`
          );
          await this.$auth.fetchUser();
        } catch (error) {
          this.$store.dispatch(
            "messagePopup/error",
            error.response.data.message,
            { root: true }
          );
        } finally {
          this.$store.dispatch("overlay/toggleOverlay");
        }
      }
    },
  },
};
