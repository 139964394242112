import { render, staticRenderFns } from "./LirPrice.vue?vue&type=template&id=589ce29b&scoped=true"
import script from "./LirPrice.vue?vue&type=script&lang=js"
export * from "./LirPrice.vue?vue&type=script&lang=js"
import style0 from "./LirPrice.vue?vue&type=style&index=0&id=589ce29b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "589ce29b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PublicLirIcon: require('/app/components/public/lir/Icon.vue').default,CoreSvgIcon: require('/app/components/core/SvgIcon.vue').default,CoreButton: require('/app/components/core/Button.vue').default})
