
export default {
  name: "SideMenu",
  props: {
    hasHead: {
      type: Boolean,
      default: false,
    },
    linksData: {
      type: Array,
      required: true,
    }
  },
  methods: {
    async logoutRequest () {
      await this.$auth.logout(/* .... */)
    }
  }
}
