
export default {
  name: "TetherToRial",
  data() {
    return {
      stepper: 1,
      formData: {
        amount: null,
      },
    }
  },
  computed: {
    minimum() {
      return 10;
    },
    preFactor() {
      let preFactor = {
        rate: this.$store.state.usdtConfig.currentPrice,
        irtAmount: 0,
        usdtAmount: 0,
        fee: 0,
        receiveAmount: 0,
      };

      if (this.formData.amount) {
        // preFactor.irtAmount = this.formData.amount;
        // preFactor.usdtAmount = parseFloat(parseFloat(this.formData.amount / this.$store.state.usdtConfig.currentPrice).toFixed(2));
        preFactor.usdtAmount = this.formData.amount;
        preFactor.irtAmount = preFactor.usdtAmount * preFactor.rate;
        if (preFactor.usdtAmount <= 200) {
          preFactor.fee = 1;
          if (preFactor.usdtAmount >= this.minimum) {
            preFactor.receiveAmount = (preFactor.irtAmount - (preFactor.fee * preFactor.rate));
          }
        } else {
          preFactor.fee = parseFloat(parseFloat((preFactor.usdtAmount / 100) * this.$auth.user.wage.fee).toFixed(2));
          preFactor.receiveAmount = (preFactor.irtAmount - parseInt(preFactor.fee * preFactor.rate));
        }
      }

      return preFactor;
    }
  },
  methods: {
    setBalance() {
      this.formData.amount = this.$auth.user.balance.USDT;
    },
    async sell() {
      if (this.formData.amount >= this.minimum) {
        this.$store.dispatch("overlay/toggleOverlay");
        this.$store.dispatch('dashboardPopupForm/closePopup');
        try {
          let payload = {
            amount: this.preFactor.usdtAmount,
          };
          let resp = await this.$axios.$post('/v1/order/sell', payload);
          await this.$auth.fetchUser();
          this.showTransactionDetail(resp.order);

        } catch (err) {
          this.$store.dispatch("messagePopup/error", err.response.data.message);
        } finally {
          this.$store.dispatch("overlay/toggleOverlay");
        }
      }
    },
    showTransactionDetail(order) {
      let status = (order.status === 'COMPLETED') ? 'confirmed' : 'pending';
      let date = new Date(order.createdAt).toLocaleDateString('fa-IR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
      let dateTime = new Date(order.createdAt).toLocaleTimeString('fa-IR');
      let payload = {
        confirmText: 'تبدیل تتر به تومان با موفقیت انجام شد.',
        title: {
          icon: 'transaction-history',
          text: 'جزئیات تبدیل تتر به تومان',
        },
        body: {
          icon: 'irt',
          status,
          irtPrice: order.price,
          usdtPrice: order.amount,
          content: [
            { label: 'نرخ معامله', value: `${(order.usdt).toLocaleString('fa-IR')} تومان` },
            { label: 'کارمزد تتر اکسچنج', value: `${(parseInt(order.fee)).toLocaleString('fa-IR')} تومان` },
            { label: 'مبلغ دریافتی', value: `${(parseInt(order.price - order.fee)).toLocaleString('fa-IR')} تومان` },
            { label: 'زمان', value: `${dateTime} - ${date}` },
            { label: 'کد پیگیری', value: order.trackingCode },
          ],
        }
      };
      this.$store.dispatch("dashboardModalFactor/setFactorDetails", payload);
      this.$store.dispatch("dashboardModalFactor/show");
    }
  }
}
