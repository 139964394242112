import { render, staticRenderFns } from "./Layout.vue?vue&type=template&id=8b05d6b4&scoped=true"
import script from "./Layout.vue?vue&type=script&lang=js"
export * from "./Layout.vue?vue&type=script&lang=js"
import style0 from "./Layout.vue?vue&type=style&index=0&id=8b05d6b4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b05d6b4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppPopupDashboardTetherDeposit: require('/app/components/app/popup/dashboard/TetherDeposit.vue').default,AppPopupDashboardTetherWithdraw: require('/app/components/app/popup/dashboard/TetherWithdraw.vue').default,AppPopupDashboardRialDeposit: require('/app/components/app/popup/dashboard/RialDeposit.vue').default,AppPopupDashboardRialWithdraw: require('/app/components/app/popup/dashboard/RialWithdraw.vue').default,AppPopupDashboardTetherToRial: require('/app/components/app/popup/dashboard/TetherToRial.vue').default,AppPopupDashboardRialToTether: require('/app/components/app/popup/dashboard/RialToTether.vue').default})
