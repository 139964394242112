
export default {
    name: 'CoreBlogDate',
    props: ['date'],
    computed: {
        jalali() {
            const newDate = new Date(this.date).toLocaleDateString('fa-IR')
            return newDate.split('/')
        }
    },
    methods: {
        getMonth(month) {
            switch (month) {
                case '۱':
                    return 'فروردین'
                    break;
                case '۲':
                    return 'اردیبهشت'
                    break;
                case '۳':
                    return 'خرداد'
                    break;
                case '۴':
                    return 'تیر'
                    break;
                case '۵':
                    return 'مرداد'
                    break;
                case '۶':
                    return 'شهریور'
                    break;
                case '۷':
                    return 'مهر'
                    break;
                case '۸':
                    return 'آبان'
                    break;
                case '۹':
                    return 'آذر'
                    break;
                case '۱۰':
                    return 'دی'
                    break;
                case '۱۱':
                    return 'بهمن'
                    break;
                case '۱۲':
                    return 'اسفند'
                    break;
                default:
                    break;
            }
        }
    }
}
