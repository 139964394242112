
export default {
  name: "RialToTether",
  data () {
    return {
      stepper: 1,
      formData: {
        amount: null,
      },
    }
  },
  computed: {
    minimum () {
      return (10 * this.$store.state.usdtConfig.currentPrice);
    },
    preFactor () {
      let preFactor = {
        rate: this.$store.state.usdtConfig.currentPrice,
        irtAmount: 0,
        usdtAmount: 0,
        fee: 0,
        receiveAmount: 0,
      };

      if (this.formData.amount) {
        preFactor.irtAmount = this.formData.amount;
        preFactor.usdtAmount = parseFloat(parseFloat(this.formData.amount / this.$store.state.usdtConfig.currentPrice).toFixed(2));
        if (preFactor.usdtAmount <= 200) {
          preFactor.fee = 1;
          if (preFactor.irtAmount >= this.minimum) {
            preFactor.receiveAmount = (preFactor.usdtAmount - 1);
          }
        } else {
          preFactor.fee = parseFloat(parseFloat((preFactor.usdtAmount / 100) * this.$auth.user.wage.fee).toFixed(2));
          preFactor.receiveAmount = (preFactor.usdtAmount - preFactor.fee);
        }
      }

      return preFactor;
    }
  },
  methods: {
    setBalance () {
      this.formData.amount = this.$auth.user.balance.IRT;
    },
    async buy () {
      if (this.formData.amount < this.minimum) {
        this.$store.dispatch("messagePopup/error", `حداقل مبلغ قابل تبدیل ${this.minimum.toLocaleString('fa-IR')} تومان می باشد.`);
      } else {
        this.$store.dispatch("overlay/toggleOverlay");
        this.$store.dispatch('dashboardPopupForm/closePopup');
        try {
          let payload = {
            amount: this.preFactor.usdtAmount,
          };
          let resp = await this.$axios.$post('/v1/order/buy', payload);
          await this.$auth.fetchUser();
          this.showTransactionDetail(resp.order);

        } catch (err) {
          this.$store.dispatch("messagePopup/error", err.response.data.message);
        } finally {
          this.$store.dispatch("overlay/toggleOverlay");
        }
      }
    },
    showTransactionDetail (order) {
      let status = (order.status === 'COMPLETED') ? 'confirmed' : 'pending';
      let fee;
      if (order.amount <= 200) {
        fee = 1;
      } else {
        fee = parseFloat(parseFloat((preFactor.usdtAmount / 100) * this.$auth.user.wage.fee).toFixed(2));
      }
      let date = new Date(order.createdAt).toLocaleDateString('fa-IR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
      let dateTime = new Date(order.createdAt).toLocaleTimeString('fa-IR');
      let payload = {
        confirmText: 'تبدیل تومان به تتر با موفقیت انجام شد.',
        title: {
          icon: 'transaction-history',
          text: 'جزئیات تبدیل تومان به تتر',
        },
        body: {
          icon: 'irt',
          status,
          irtPrice: order.price,
          usdtPrice: order.amount,
          content: [
            { label: 'نرخ معامله', value: `${(order.usdt).toLocaleString('fa-IR')} تومان` },
            { label: 'کارمزد تتر اکسچنج', value: `${fee.toLocaleString('fa-IR')} تتر` },
            { label: 'مقدار دریافتی', value: `${(order.amount - fee).toLocaleString('fa-IR')} تتر` },
            { label: 'زمان', value: `${dateTime} - ${date}` },
            { label: 'کد پیگیری', value: order.trackingCode },
          ],
        }
      };
      this.$store.dispatch("dashboardModalFactor/setFactorDetails", payload);
      this.$store.dispatch("dashboardModalFactor/show");
    }
  }
}

/*
*
* {
    "order": {
        "amount": 10,
        "price": 438000,
        "usdt": 43800,
        "id": "fe5ac77f-b4ba-4024-a5cd-d49796efa3be",
        "trackingCode": "TVP4xvFF",
        "type": "BUY",
        "status": "COMPLETED",
        "createdAt": "2023-01-26T10:20:06.395Z",
        "updatedAt": "2023-01-26T10:20:06.395Z"
    },
    "price": {
        "usdt": 43800,
        "lowPrice": 43800,
        "hightPrice": 43800,
        "percentageDifference": 0
    }
}
*
* */

