
export default {
  name: "TetherWithdraw",
  data() {
    return {
      formData: {
        amount: 1,
      },
      wallet: {
        selected: null,
        accounts: [],
        // options: [],
        addNewCard: {
          formFlag: false,
        }
      },
    }
  },
  computed: {
    walletOptions() {
      let wallets = this.wallet.accounts;
      let newOptions = [];
      wallets.forEach(wallet => {
        newOptions.push({
          id: wallet.id,
          icon: '/layouts/icons/networks/trx.svg',
          title: wallet.title ? wallet.title : 'عنوان ندارد',
          body: [
            `آدرس:${wallet.address}`,
          ]
        });
      });
      return newOptions;
    }
  },
  methods: {
    setBalance() {
      this.formData.amount = this.$auth.user.balance.USDT;
    },
    async getWalletsList() {
      if (this.$store.state.cryptoWallets.allWallets.length === 0) {
        await this.$store.dispatch("cryptoWallets/getAll");
        this.wallet.accounts = this.$store.state.cryptoWallets.allWallets;
      } else {
        this.wallet.accounts = this.$store.state.cryptoWallets.allWallets;
      }
    },
    async withdraw() {
      if (this.wallet.selected) {
        if (this.$auth.user.isKYC) {
          if (this.formData.amount && this.formData.amount >= 10) {
            if (this.formData.amount <= this.$auth.user.balance.USDT) {
              this.$store.dispatch("dashboardPopupForm/closePopup");
              this.$store.dispatch("overlay/toggleOverlay");
              try {
                let payload = {
                  type: "USDT",
                  amount: this.formData.amount,
                  walletId: this.wallet.selected.id,
                };
                let resp = await this.$axios.$post('/v1/withdrawal', payload);
                let address = this.wallet.selected.body.filter(item => item.label === 'آدرس');
                let withdrawInfo = {
                  status: resp.withdrawal.status,
                  amount: resp.withdrawal.amount,
                  address: address[0].value,
                  createdAt: resp.withdrawal.createdAt,
                };
                this.showWithdrawInfo(withdrawInfo);
                await this.$auth.fetchUser();
                this.$store.dispatch("usdtConfig/set", resp.price);
                this.$store.dispatch("lirConfig/set", resp.lirPrice);
              } catch (err) {
                this.$store.dispatch("messagePopup/error", err.response.data.message);
              } finally {
                this.$store.dispatch("overlay/toggleOverlay");
              }
            } else {
              this.$store.dispatch("messagePopup/error", "موجودی تتر شما کافی نمی باشد.");
            }
          } else {
            this.$store.dispatch("messagePopup/error", "حداقل مقدار برای برداشت، معادل 10 تتر می باشد.");
          }
        } else {
          this.$store.dispatch("messagePopup/error", "برای برداشت از حساب ابتدا می بایست احراز هویت کنید.");
        }
      } else {
        this.$store.dispatch("messagePopup/error", "لطفا یک کیف پول جهت برداشت تتر انتخاب کنید.");
      }
    },
    showWithdrawInfo(order) {
      let date = new Date(order.createdAt).toLocaleDateString('fa-IR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
      let dateTime = new Date(order.createdAt).toLocaleTimeString('fa-IR');
      let payload = {
        confirmText: 'درخواست برداشت تتر با موفقیت ثبت شد.',
        title: {
          icon: 'transaction-history',
          text: 'جزئیات درخواست برداشت تتر',
        },
        body: {
          icon: 'usdt',
          status: order.status,
          irtPrice: 0,
          usdtPrice: order.amount,
          content: [
            { label: 'شبکه انتقال', value: 'TRC20', image: '/layouts/icons/networks/trx.svg' },
            { label: 'کارمزد انتقال', value: '1 تتر' },
            { label: 'مقدار دریافتی', value: `${order.amount - 1} تتر` },
            { label: 'آدرس دریافت', value: order.address, hasCopy: true },
            { label: 'زمان', value: `${dateTime} - ${date}` },
          ],
        }
      };
      this.$store.dispatch("dashboardModalFactor/setFactorDetails", payload);
      this.$store.dispatch("dashboardModalFactor/show");
    },

  },
  async fetch() {
    await this.getWalletsList();
  }
}
