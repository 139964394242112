
export default {
  name: "CoreLirPrice",
  props: {
    boxStyle: {
      type: String,
      default: "index",
    },
    price: {
      type: Object,
      default: {
        lir: 0,
        lowLirPrice: 0,
        hightLirPrice: 0,
        percentageDifference: 0,
      },
    },
  },
};
