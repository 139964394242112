import { render, staticRenderFns } from "./Overlay.vue?vue&type=template&id=26a95f5e&scoped=true"
import script from "./Overlay.vue?vue&type=script&lang=js"
export * from "./Overlay.vue?vue&type=script&lang=js"
import style0 from "./Overlay.vue?vue&type=style&index=0&id=26a95f5e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26a95f5e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreLoading: require('/app/components/core/Loading.vue').default})
