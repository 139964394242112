
export default {
  name: "CoreSvgIcon",
  props: {
    name: {
      type: String,
      required: true
    },
    fill: {
      type: String,
    },
  }
}
