
import { LineChart } from 'chartist';

export default {
  name: "CorePrice",
  props: {
    boxStyle: {
      type: String,
      default: "index",
    },
    price: {
      type: Object,
      default: {
        usdt: 0,
        lowPrice: 0,
        hightPrice: 0,
        percentageDifference: 0,
        priceInLastMonth: 0
      },
    },
    lirPrice: {
      type: Object,
      default: {
        lir: 0,
        lowLirPrice: 0,
        hightLirPrice: 0,
        percentageDifference: 0,
        priceInLastMonth: 0
      },
    },
  },

  data() {
    return {
      selectedCurrency: 'tether',
      sparkOptions: {
        height: '1rem',
        width: '4rem',
        showPoint: false,
        fullWidth: true,
        chartPadding: { top: 0, right: 0, bottom: 0, left: 0 },
        axisX: { showGrid: false, showLabel: false, offset: 0 },
        axisY: { showGrid: false, showLabel: false, offset: 0 }
      },
      data: { series: [[]] },
      usdtData: [],
      lirData: []
    }
  },

  watch: {
    selectedCurrency(newVal) {
      if (newVal === 'tether') {
        this.data.series[0] = this.usdtData
      } else {
        this.data.series[0] = this.lirData
      }
    }
  },

  beforeMount() {
    if (this.$store.state.usdtConfig?.priceInLastMonth) {
      this.$store.state.usdtConfig?.priceInLastMonth.forEach(price => {
        this.usdtData.push(price?.price)
      });
      this.data.series[0] = this.usdtData;
    }
    if (this.$store.state.lirConfig?.priceInLastMonth) {
      this.$store.state.lirConfig?.priceInLastMonth.forEach(price => {
        this.lirData.push(price?.price)
      });
    }
  },

  mounted() {
    new LineChart('.chart', this.data, this.sparkOptions)
  },

  methods: {
    gotoChart() {
      console.log('asd')
      this.$router.push('/tether-chart')
    }
  }
};
